import cn from 'clsx';

import { createElement, ReactNode, KeyboardEvent, useRef, useMemo } from 'react';

import { Badge, BadgeProps, BadgeSize } from '@swe/shared/ui-kit/components/badge';
import { Bullet, BulletColor, BulletSize } from '@swe/shared/ui-kit/components/bullet';
import { Button, ButtonProps, ButtonSize } from '@swe/shared/ui-kit/components/button';
import { IconComponent, IconSize } from '@swe/shared/ui-kit/components/icon';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import {
  Colors,
  ComponentHasChildren,
  ComponentHasClassName,
  ComponentHasColor,
  ComponentHasSize,
  Sizes,
} from '@swe/shared/ui-kit/types/common-props';
import { isEnterOrSpacePressed } from '@swe/shared/utils/keyboard';

import styles from './styles.module.scss';

export type SectionHeadingSize = Sizes<'md' | 'sm' | 'lg' | 'xl'>;
export type SectionHeadingColor = BulletColor;
export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type SectionHeadingProps = ComponentHasClassName & {
  level?: HeadingLevel;
  bullet?: string | IconComponent | { icon: IconComponent; onClick?: () => void; type?: 'button' };
  icon?: IconComponent;
  subHeading?: ReactNode;
  onClick?: () => void;
  badge?: Omit<BadgeProps, 'size' | 'className'>;
  link?: Pick<ButtonProps, 'children' | 'onClick'>;
  button?: Omit<ButtonProps, 'size' | 'className'> | (Omit<ButtonProps, 'size' | 'className'> | undefined)[];
  action?: Pick<ButtonProps, 'icon' | 'onClick'>;
  trailIcon?: IconComponent;
  trailBlock?: ReactNode;
  colorText?: Colors<'danger'> | 'inherit';
  noPadding?: boolean;
  badgeStopPropagation?: boolean;
  href?: string;
} & ComponentHasSize<SectionHeadingSize> &
  ComponentHasColor<SectionHeadingColor> &
  ComponentHasChildren;

export const SectionHeading = ({
  level = 2,
  className,
  onClick,
  size = 'md',
  bullet,
  icon: LeadIcon,
  color,
  colorText,
  children,
  subHeading,
  badge,
  link,
  button,
  action,
  trailBlock,
  trailIcon: TrailIcon,
  noPadding,
  href,
  badgeStopPropagation,
}: SectionHeadingProps) => {
  const { sectionHeader } = useTheme();
  const buttons = (Array.isArray(button) ? button : [button]).filter(Boolean);
  const bulletType = (typeof bullet === 'object' && bullet.type) || 'bullet';
  const bulletValue = typeof bullet === 'object' && bullet.icon ? bullet.icon : (bullet as string | IconComponent);
  const bulletOnClick = typeof bullet === 'object' && bullet.onClick ? bullet.onClick : undefined;
  const rootRef = useRef(null);

  const keyDownHandler = useMemo(() => {
    if (!onClick) return;
    return (e: KeyboardEvent<HTMLDivElement | HTMLAnchorElement>) => {
      if (e.target === rootRef.current && isEnterOrSpacePressed(e)) {
        e.preventDefault();
        onClick();
      }
    };
  }, [onClick]);

  const Element = href ? 'a' : 'div';

  return (
    <Element
      ref={rootRef}
      className={cn(
        styles.root,
        onClick && styles._clickable,
        styles[`_size_${size}`],
        noPadding && styles._noPadding,
        className,
      )}
      onClick={onClick}
      onKeyDown={keyDownHandler}
      tabIndex={onClick && 0}
      href={href}
    >
      {LeadIcon && (
        <div className={styles.lead}>
          <LeadIcon
            className={styles.leadIcon}
            size={sectionHeader[size].iconSize as IconSize}
          />
        </div>
      )}
      {bulletValue !== undefined && (
        <div className={styles.lead}>
          {bulletType === 'bullet' ? (
            <Bullet
              size={sectionHeader[size].bulletSize as BulletSize}
              value={bulletValue}
              onClick={bulletOnClick}
              color={color}
            />
          ) : (
            <Button
              size={sectionHeader[size].buttonSize as ButtonSize}
              icon={typeof bullet === 'object' && bullet.icon}
              onClick={bulletOnClick}
              ariaLabel="Back"
              color="light"
            />
          )}
        </div>
      )}
      <div className={styles.heading}>
        {children &&
          createElement(
            `h${level}`,
            {
              className: cn(styles.mainHeading, colorText && styles[`_mainHeading_${colorText}`]),
            },
            children,
          )}
        {subHeading && <div className={styles.subHeading}>{subHeading}</div>}
      </div>
      {(badge || link || buttons.length > 0 || action || trailBlock || TrailIcon) && (
        <div className={styles.trail}>
          {trailBlock && <div>{trailBlock}</div>}
          {badge && (
            <Badge
              {...badge}
              stopPropagation={badgeStopPropagation ?? typeof onClick === 'function'}
              size={sectionHeader[size].badgeSize as BadgeSize}
            />
          )}
          {link && (
            <Button
              variant="link"
              onClick={link.onClick}
              ariaLabel="View All"
              size={sectionHeader[size].buttonSize as ButtonSize}
            >
              {link.children}
            </Button>
          )}
          {buttons.length > 0 && (
            <div className={styles.buttonBlock}>
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  color="light"
                  {...(button as ButtonProps)}
                  size={sectionHeader[size].buttonSize as ButtonSize}
                />
              ))}
            </div>
          )}
          {action && (
            <Button
              {...(action as ButtonProps)}
              size={sectionHeader[size].buttonSize as ButtonSize}
              color="light"
            />
          )}
          {TrailIcon && (
            <TrailIcon
              className={styles.trailIcon}
              size={sectionHeader[size].iconSize as IconSize}
            />
          )}
        </div>
      )}
    </Element>
  );
};

export default SectionHeading;
