import { QueryClient, QueryClientProvider, focusManager, hydrate, dehydrate } from '@tanstack/react-query';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ComponentHasChildren } from '@swe/shared/ui-kit/types/common-props';
import { isSSR } from '@swe/shared/utils/environment';

type EndpointFactoryProviderProps = ComponentHasChildren & {
  revalidateOnInit?: boolean;
  externalApiHost?: string;
  externalApiBasePath?: string;
};

const context = createContext<{ pauseRevalidateOnFocus: () => void; resumeRevalidateOnFocus: () => void }>(null!);

const QueryControlsProvider = context.Provider;
const useQueryControls = () => useContext(context);

const QueryProvider = ({
  children,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  revalidateOnInit = true,
  externalApiBasePath,
  externalApiHost,
}: EndpointFactoryProviderProps) => {
  useMemo(() => {
    if (!isSSR) {
      window.__sw_externalApiBasePath = externalApiBasePath;
      window.__sw_externalApiHost = externalApiHost;
    }
  }, [externalApiBasePath, externalApiHost]);

  const [revalidateOnFocus, setRevalidateOnFocus] = useState(true);
  const pauseRevalidateOnFocus = useCallback(() => setRevalidateOnFocus(false), []);
  const resumeRevalidateOnFocus = useCallback(() => setRevalidateOnFocus(true), []);

  useEffect(() => {
    focusManager.setFocused(revalidateOnFocus ? undefined : true);
  }, [revalidateOnFocus]);

  return (
    <QueryControlsProvider
      value={useMemo(
        () => ({ pauseRevalidateOnFocus, resumeRevalidateOnFocus }),
        [pauseRevalidateOnFocus, resumeRevalidateOnFocus],
      )}
    >
      {children}
    </QueryControlsProvider>
  );
};

export {
  useQueryControls,
  QueryProvider,
  QueryClientProvider,
  QueryClient,
  hydrate as hydrateQueryClient,
  dehydrate as dehydrateQueryClient,
};
export type { EndpointFactoryProviderProps };
export default QueryProvider;
