import { usePersistState } from '@swe/shared/providers/persist-state';

import { CommonQueryParams } from '@swe/shared/providers/router/constants';
import { capitalize } from '@swe/shared/utils/string';

import { useCallback } from 'react';

import { useStoreConfig } from 'common/providers/config';
import { useRouterNavigate, useRouterQuery } from 'common/router';
import { ProductSaleType } from 'entities/product/product';
import { StoreSaleType } from 'entities/shop/sale-type';

const SALE_TYPE_STORAGE_KEY = 'Cart/SaleType';
const DEFAULT_SALE_TYPE = ProductSaleType.RECREATIONAL;

type UseSaleTypeReturn =
  | {
      isMedicalMenuEnabled: true;
      saleType: Exclude<StoreSaleType, undefined>;
      setSaleType: (saleType: StoreSaleType) => Promise<void>;
    }
  | {
      isMedicalMenuEnabled: false;
      saleType: undefined;
      setSaleType: undefined;
    };

let initGlobalHeadersSaleType = true;

const getDefaultSaleType = (isMedicalMenuEnabled: boolean) => {
  return isMedicalMenuEnabled ? DEFAULT_SALE_TYPE : null;
};

type RequiredSaleType = Exclude<StoreSaleType, undefined>;

const isExistedSaleTypeParam = (saleType: string) => {
  return [ProductSaleType.MEDICAL.toLowerCase(), ProductSaleType.RECREATIONAL.toLowerCase()].includes(saleType);
};

const useSaleType = () => {
  const { medicalMenuEnabled: isMedicalMenuEnabled, saleTypeBasedRoutingEnabled } = useStoreConfig();
  const defaultValue = getDefaultSaleType(isMedicalMenuEnabled);

  // TODO: got rid of cookie based state after migrate all store with medical menus
  const [_saleType, _setSaleType] = usePersistState<Exclude<StoreSaleType, undefined> | null>(
    'cookies',
    SALE_TYPE_STORAGE_KEY,
    defaultValue,
  );

  const navigate = useRouterNavigate();
  const query = useRouterQuery();
  const saleType =
    saleTypeBasedRoutingEnabled &&
    isMedicalMenuEnabled &&
    isExistedSaleTypeParam(query[CommonQueryParams.SaleType] as string)
      ? capitalize(query[CommonQueryParams.SaleType] as string)
      : _saleType;

  const setSaleType = useCallback(
    (nextSaleType: Exclude<StoreSaleType, undefined> | null) => {
      _setSaleType(nextSaleType);
      if (saleTypeBasedRoutingEnabled && isMedicalMenuEnabled) {
        navigate({ query: { ...query, [CommonQueryParams.SaleType]: nextSaleType?.toLowerCase() } });
      }
    },
    [_setSaleType, navigate, query, saleTypeBasedRoutingEnabled, isMedicalMenuEnabled],
  );

  if (saleType !== null && initGlobalHeadersSaleType) {
    initGlobalHeadersSaleType = false;
  }

  if (isMedicalMenuEnabled && saleType === null) {
    setSaleType(DEFAULT_SALE_TYPE);
  }
  if (!isMedicalMenuEnabled && saleType !== null) {
    setSaleType(null);
  }

  return {
    saleType: saleType ?? undefined,
    setSaleType,
    isMedicalMenuEnabled,
  } as UseSaleTypeReturn;
};

const getSaleType = (medicalMenuEnabled: boolean, reqSaleType: StoreSaleType): StoreSaleType => {
  return medicalMenuEnabled
    ? [ProductSaleType.MEDICAL, ProductSaleType.RECREATIONAL].includes(reqSaleType!)
      ? reqSaleType
      : DEFAULT_SALE_TYPE
    : undefined;
};

export type { RequiredSaleType };
export {
  useSaleType,
  getSaleType,
  getDefaultSaleType,
  SALE_TYPE_STORAGE_KEY,
  DEFAULT_SALE_TYPE,
  isExistedSaleTypeParam,
};
