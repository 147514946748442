import { Serializable } from '@swe/shared/types/json';

import { CaregiverPatient } from 'endpoints/cart/get-caregiver-patients-list';

import { ScheduleInterval } from 'entities/common/schedule';
import { DeliveryStore } from 'entities/delivery/delivery';
import { Product, ProductCategory, ProductSaleType, ProductVariant } from 'entities/product/product';
import { FullShopFulfillmentType, LocationCoords } from 'entities/shop/info';
import { StoreSaleType } from 'entities/shop/sale-type';

enum OrderStatus {
  NewOrder = 'NewOrder',
  OrderPlaced = 'OrderPlaced',
  OrderConfirmed = 'OrderConfirmed',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  DroppedByUser = 'DroppedByUser',
  Closed = 'Closed',
}

enum OrderProcessStatus {
  DocumentVerification = 'DocumentVerification',
  Confirming = 'Confirming',
  Confirmed = 'Confirmed',
  Packing = 'Packing',
  ReadyForPickUp = 'ReadyForPickUp',
  PickedUp = 'PickedUp',
  ActionRequired = 'ActionRequired',
  Cancelled = 'Cancelled',
  CancelledByTimeout = 'CancelledByTimeout',
  Packed = 'Packed',
  OnTheWay = 'OnTheWay',
  Delivered = 'Delivered',
  OutForDelivery = 'OutForDelivery',
}

const ORDER_COMPLETED_STATUS = [
  OrderStatus.Completed,
  OrderStatus.Closed,
  OrderStatus.Cancelled,
  OrderStatus.DroppedByUser,
];

type ChatId = EntityID<string>;

enum ChatType {
  Store = 'customerToDispatcher',
  Driver = 'customerToCourier',
}

export enum ActionApplicationMode {
  None = 'None',
  Regular = 'Regular',
  Additional = 'Additional',
  Supersedes = 'Supersedes',
  PriceCorrection = 'PriceCorrection',
  Referral = 'Referral',
  PersonalPromo = 'PersonalPromo',
  Loyalty = 'Loyalty',
  Ultimate = 'Ultimate',
}

export enum PaymentMethod {
  Cash = 'Cash',
  DebitCard = 'DebitCard',
  CanPay = 'CanPay',
  AeroPay = 'Aeropay',
  GiftCard = 'GiftCard',
  TwoAccept = 'TwoAccept',
}

type Chat = {
  chatId: ChatId;
  isClosed: boolean;
  lastMessageDate: DateISOString;
  type: ChatType;
  total: number;
  unreadFromClient: number;
  unreadFromCourier: number;
  unreadFromSystemUser: number;
};

type OrderId = EntityID<number>;

export type OrderReceiptItem = {
  title: string;
  price: Price$;
  titleValue: string | null;
  children?: OrderReceiptItem[];
};

export type OrderReceipt = {
  total?: Price$;
  totalBeforePromo?: Price$;
  items?: OrderReceiptItem[];
};

export type PurchaseLimit = NamedEntity & {
  maxAmount: number;
  limitAmount: number;
  nameAbbr: string;
  uomAbbr: string;
};

type ReducedProduct = {
  catalogProduct: Product | null;
  discountedPrice: number | null;
  images: string[];
  name: string;
  price: number;
  qty: number;
  saleType: ProductSaleType;
  variantId: EntityID<number>;
  variantName: string;
  promoName?: string;
};

type PromoRecommendations = {
  actionApplicationModeId: number;
  id: number;
  name: string;
  shortName: string;
  minCartAmountNeeded: number;
  applicationMode: ActionApplicationMode;
  amountLimitPerInvoiceOnSubtotal: boolean;
  discountAmount: number | null;
  discountPercent: number | null;
  minCartAmount: number | null;
  filter: Record<string, any>;
  displayInUpsellModal: boolean;
  matchedUserSegmentIds: number[];
};

type CartPromoRecommendations = PromoRecommendations & { isApplied: boolean };

type Order = {
  id: OrderId;
  appliedPromoIds?: number[];
  storeId?: EntityID<number>;
  accountId?: EntityID<number>;
  priceTotal: number;
  itemsQuantity?: number;
  status: OrderStatus;
  statusTitle?: string;
  orderNumber?: EntityID<number>;
  fulfillmentType?: FullShopFulfillmentType;
  confirmedAt?: DateISOString;
  processStatus: OrderProcessStatus;
  saleType?: StoreSaleType;
  items?: ReducedProduct[];
  location?: {
    address?: string;
    coordinates?: LocationCoords;
    secondaryAddress?: string;
  };
  scheduleInterval?: ScheduleInterval;
  scheduleIntervalId?: number;
  scheduleDate?: DateISOString;
  completedAt?: DateISOString;
  fulfillmentDate?: DateISOString;
  eta?: DateISOString;
  paymentMethodType?: PaymentMethod;
  promocodes?: string[];
  loyalty?: {
    bonusToMoney: number;
    maxPointToRedeem?: number;
    moneyToRedeem?: number;
    pointsToRedeem?: number;
    presets?: number[];
  };
  notes?: string;
  limits?: PurchaseLimit[];
  receipt?: OrderReceipt;
  store?: DeliveryStore;
  courier?: {
    name: string;
    coordinates: LocationCoords;
  };
  chats?: Chat[];
  courierOnTheWay?: boolean;
  reservedUntil?: DateISOString;
  orderVisit?: { visitId: number; comment: string; created: DateISOString };
  amountToCheckMinOrderAmount: number;
  isResident?: boolean;
  invoiceId?: number;
  canCancel: boolean;
  eventData?: Serializable;
  hideForPatient?: boolean;
  patient?: CaregiverPatient;
  caregiver?: CaregiverPatient;
  isGuestOrder?: boolean;
  cartAppliedPromos?: PromoRecommendations[];
  cartPromoRecommendations?: PromoRecommendations[];
  upsellModalPromoRecommendations?: PromoRecommendations[];
  upsellModalAppliedPromos?: PromoRecommendations[];
  analytics?: {
    isFirstOrderByStore?: boolean;
  };
  isAsap?: boolean;
};

const mapReducedProductToFullProduct = (rProduct: ReducedProduct) => ({
  id: rProduct.name.concat(rProduct.variantId.toString()),
  name: rProduct.name,
  category: rProduct.catalogProduct?.category as ProductCategory,
  qty: rProduct.qty,
  variants: [
    {
      id: String(rProduct.variantId),
      // TODO: Dirty hack, need to be fixed properly by typing product entity
      detailedLabDataExists: false,
      price: undefined as any,
      promoPrice: undefined,
      name: rProduct.variantName,
      images: rProduct.images as ProductVariant['images'],
      availableQty: 1,
      reminderStatus: null,
      isPacked: true,
      qtyStepSize: 1,
    },
  ],
});

const isOrderNotCompleted = (order: Order) => !ORDER_COMPLETED_STATUS.includes(order.status);

export type { Order, Chat, ChatId, OrderId, PromoRecommendations, CartPromoRecommendations };
export { OrderProcessStatus, OrderStatus, ChatType, mapReducedProductToFullProduct, isOrderNotCompleted };
